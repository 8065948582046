import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text2 as="h2">PRIVACY POLICY</Text2>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICATION</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Data Controller</strong>: Ironhack School S.L.
                (hereinafter, “Ironhack”)
              </li>
              <li>
                <strong>VAT: </strong>B-86783230
              </li>
              <li>
                <strong>Registered Office</strong>: C/ CASTELLO, 115, BAJOS 7 -
                28006 MADRID
              </li>
              <li>
                <strong>Data Protection Officer (DPO)</strong>: You can contact
                our Data Protection Officer by sending a letter to Ironhack´s
                registered address, to the attention of the “Data Protection
                Officer” or by sending an e-mail to{' '}
                <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMATION AND CONSENT</Text2>
          <Text3 as="p">
            By accepting this Privacy Policy, the User has been duly informed
            and has granted his informed, express, free and unambiguous consent
            for the processing by Ironhack of the personal data collected
            through the forms of the website{' '}
            <a href="https://www.ironhack.com/en">www.ironhack.com</a>{' '}
            (hereinafter, the "Website").
          </Text3>
          <Text3 as="p">
            Ironhack shall not be liable for any data processing that is carried
            out through websites that are not directly controlled by Ironhack.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OBLIGATION OF PROVIDING DATA</Text2>
          <Text3 as="p">
            The data requested in the forms of the Website is mandatory (unless
            specified otherwise) and necessary to carry out the purposes
            indicated below. Therefore, if such data is not provided or is
            incorrectly provided, Ironhack shall not be able to furnish any
            service that you may have requested.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            PURPOSES OF THE PROCESSING OF YOUR DATA AND STORAGE PERIOD
          </Text2>
          <Text3 as="p">
            Personal data provided by the User{' '}
            <strong>through the contact form of the Website</strong> will be
            processed by Ironhack for the following purpose:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Solving inquiries, managing suggestions and resolving complaints
                that the User has reported to Ironhack. Your data will be stored
                for this purpose during the entire period of time during which
                your query is managed and, even later, until any possible
                actions derived from such query have expired.
              </li>
            </ul>
            Personal data provided by the User{' '}
            <strong>through the Newsletter Request form of the Website</strong>{' '}
            will be processed by Ironhack for the following purpose:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Processing your request of being informed about Ironhack´s
                latest news and events. Your data will be stored until you
                withdraw your consent.
              </li>
            </ul>
            Personal data provided by{' '}
            <strong>the Ironhack Courses application form </strong>
            of the Website will be processed by Ironhack for the following
            purposes:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Assessing and managing your application and carrying out the
                necessary actions for the selection of the students. Your
                personal data will be stored during the entire period of time
                which your query is managed and, even later, until any possible
                actions derived from such query have expired.
              </li>
              <li>
                In case you check the corresponding box, processing your request
                of being informed about Ironhack´s latest news and events. Your
                data will be stored until you withdraw your consent.
              </li>
            </ul>
            Personal data provided by{' '}
            <strong>
              the Ironhack Hiring Partners application form of the Website
            </strong>{' '}
            will be processed by Ironhack for the following purpose:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Assessing and managing the condition of being an Ironhack Hiring
                Partner. Your personal data will be stored during the entire
                period of time which your query is managed and, even later,
                until any possible actions derived from such query have expired.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">PERSONAL DATA PROCESSED BY IRONHACK</Text2>
          <Text3 as="p">
            In connection with the “contact form” available on the Website,
            Ironhack will process the following data:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identification data: name, surname, etc. </li>
              <li>Contact details: email address, telephone number. </li>
            </ul>
            In connection with the “Neswletter Request” form available on the
            Website, Ironhack will process the following data:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Contact details: email address.</li>
            </ul>
            In connection with the “Ironhack Courses application form” available
            on the Website, Ironhack will process the following data:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identification data: name, surname, etc. </li>
              <li>Contact details: email address, telephone number, etc. </li>
            </ul>
            In connection with the “Ironhack Hiring Partners application form”
            available on the Website, Ironhack will process the following data:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identification data: name, surname, etc. </li>
              <li>Employment details: job position, etc. </li>
              <li>Contact data: e-mail address, telephone number, etc.</li>
            </ul>
            In the event that the User provides data of third parties, the User
            declares to have obtained the consent of such third party and
            undertakes to inform such third parties of the contents of this
            Privacy Policy. The User accepts that Ironhack waives any liability
            in this regard.
          </Text3>
          <Text3 as="p">
            Notwithstanding the foregoing, Ironhack may carry out periodic
            verifications and adopt the corresponding due diligence measures, in
            accordance with the relevant data protection regulations.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COOKIES POLICY</Text2>
          <Text3 as="p">
            Click{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              here
            </Link>{' '}
            to see our cookie policy
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LAWFULNESS OF THE DATA PROCESSING</Text2>
          <Text3 as="p">
            Your data will be processed on the following legal basis:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                The processing of the User´s personal data on the occasion of
                solving inquiries, managing suggestions and resolving complaints
                is based on the consent provided by the User.
              </li>
              <li>
                The processing of the User´s personal data on the occasion of
                being informed about Ironhack´s latest news and events is based
                on the consent provided by the User.
              </li>
              <li>
                The processing of the User´s personal data on the occasion of
                assessing and managing your application and carrying out the
                necessary actions for the selection of the students is based on
                the contractual relationship between Ironhack and the User.
              </li>
              <li>
                The processing of the User´s personal data on the occasion of
                assessing and managing the condition of being an Ironhack Hiring
                Partner is based on the contractual relationship between
                Ironhack and the User.
              </li>
            </ul>
            Please, take into account that you can withdraw your consent at any
            time and the withdrawal of your consent shall not affect the
            lawfulness of any processing carried out before such withdrawal.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DATA RECIPIENTS</Text2>
          <Text3 as="p">Your personal data may be communicated to: </Text3>
          <Text3 as="p">
            <ul>
              <li>The Public Administrations, in the cases foreseen by law.</li>
              <li>
                Judges, Courts and Security Bodies, in the cases foreseen by
                law.
              </li>
              {/* Removed because of task https://app.clickup.com/t/1qe3yg2*/}
              {/* <li>*/}
              {/*  Services with marketing &amp; data mgmt. purposes:{' '}*/}
              {/*  <ul>*/}
              {/*    <li>Mailchimp</li>*/}
              {/*    <li>Pipedrive</li>*/}
              {/*    <li>Google Analytics</li>*/}
              {/*    <li>Hotjar</li>*/}
              {/*    <li>Facebook Custom Audience</li>*/}
              {/*  </ul>*/}
              {/* </li>*/}
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INTERNATIONAL DATA TRANSFERS</Text2>
          <Text3 as="p">
            Personal information may be disclosed and transferred to:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campuses in Mexico, U.S. &amp; Brazil</li>
              <li>Mailchimp Inc. (U.S.)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESPONSABILITY OF THE USER</Text2>
          <Text3 as="p">The User:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Guarantees that he/she is over 18 years old and that the data
                provided to Ironhack is true, exact, complete and updated. For
                these purposes, the User is responsible for the truthfulness of
                all the data provided and will keep the information updated in a
                timely manner.
              </li>
              <li>
                Guarantees that he/she has informed of the contents of this
                Privacy Policy to any third parties whose data has been provided
                by the User, if any. He/she also guarantees that the third
                party’s authorization for the provision of such third party’s
                data to Ironhack for the purposes herein indicated has been
                obtained.
              </li>
              <li>
                Shall be responsible for any false or inaccurate information
                provided, and shall be liable for any direct or indirect damages
                caused to Ironhack or third parties.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMMERCIAL COMMUNICATIONS</Text2>
          <Text3 as="p">
            One of the purposes for which Ironhack processes personal data
            provided by Users is to send them electronic communications with
            information regarding events or relevant news for Users. At any case
            such type of communications will be addressed only and exclusively
            to those Users who have expressly expressed their consent.
          </Text3>
          <Text3 as="p">
            In the event that the User wishes to stop receiving commercial or
            promotional communications from Ironhack, he can request the
            cancellation of the service by sending an email to the following
            email address:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">YOUR RIGHTS</Text2>
          <Text3 as="p">
            The User can contact Ironhack by sending written communication to
            the address Calle Castello, 115B, Local 7, 28006, Madrid, with the
            reference “Data Protection”, or by sending an e-mail to the address{' '}
            <a href="mailto:yolanda@ironhack.com">yolanda@ironhack.com</a>{' '}
            attaching a copy of your ID, at any time and free of charge. The
            User shall at all times be able:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>To withdraw his/her consent. </li>
              <li>
                To obtain confirmation about whether personal data concerning
                the User is being processed by Ironhack.
              </li>
              <li>To access his/her personal data.</li>
              <li>To rectify inaccurate or incomplete data.</li>
              <li>To request the erasure of his/her data.</li>
              <li>
                To restrict the processing of personal data whenever any of the
                conditions of the data protection regulations are met.
              </li>
              <li>To request the portability of his/her data.</li>
              <li>
                File a complaint regarding the protection of his/her personal
                data with the competent national supervisory authority (In case
                of Spain, the Spanish Data Protection Agency).
              </li>
            </ul>
            These rights shall only be exercised where the requirements set
            forth in the current regulations are duly met.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SECURITY MEASURES</Text2>
          <Text3 as="p">
            Ironhack shall process personal data at all times in a confidential
            manner, in accordance with any relevant regulations, by adopting the
            necessary technical and organizational measures in order to ensure
            the security of the data and to avoid its alteration, loss,
            unauthorized processing or access. For the adoption of such security
            measures, the state of the technology, the nature of the data
            processed and the risks involving any possible breaches, shall be
            taken into account.
          </Text3>
          <Text3 as="p">Last updated: 10/08/2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. All rights reserved{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "en" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('en')(PrivacyPolicy);
